import {
  Box,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
} from '@mui/material'
import React from 'react'
import { palette } from '../../styles/theme'
import { handleClickOutside } from '../../utils/general'
import Button from '../Button'
import DialogHeader from '../DialogHeader'

export interface DialogProps extends MuiDialogProps {
  open: boolean
  children?: React.ReactNode
  title?: string
  subtitle?: string
  overtitle?: string
  primaryButtonText?: string
  primaryButtonAction?: () => void
  secondaryButtonText?: string
  secondaryButtonAction?: () => void
  onClickClose: () => void
}

const Dialog: React.FC<DialogProps> = ({
  open,
  onClickClose,
  children,
  title,
  subtitle,
  overtitle,
  primaryButtonText,
  primaryButtonAction,
  secondaryButtonText,
  secondaryButtonAction,
}) => {
  return (
    <MuiDialog open={open} onClick={(e) => handleClickOutside(e, onClickClose)}>
      <Box
        sx={{
          py: 2,
          px: 2,
          maxWidth: '616px',
          backgroundColor: palette.background.paper,
        }}
      >
        <Box sx={{ width: '600px' }}></Box>
        <DialogHeader
          overtitle={overtitle}
          title={title}
          subtitle={subtitle}
          onClickClose={onClickClose}
        />
        <Box my={2}>{children}</Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            mt: 2,
          }}
        >
          {secondaryButtonText && (
            <Button
              onClick={secondaryButtonAction}
              variant="outlined"
              fullWidth
            >
              {secondaryButtonText}
            </Button>
          )}
          {primaryButtonText && (
            <Button fullWidth onClick={primaryButtonAction}>
              {primaryButtonText}
            </Button>
          )}
        </Box>
      </Box>
    </MuiDialog>
  )
}

export default Dialog
